import table from "../assets/images/table.jpg";


const Table = ({children}) => <div style={styles.container}>{children}</div>


const styles = {
    container: {
        backgroundImage: `url(${table})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat", /* Запрет на повторение */
        backgroundPosition: "center center", /* Центрирование картинки */
        backgroundAttachment: "fixed", /* Фиксация фона */
        height: "100vh",
        minHeight: "600px",
        width: "100vw"
    }
}

export default Table;