import React from "react";
import { useAudioPlayer } from "react-use-audio-player";
import radio from "../assets/images/radio.png";


const Radio = () => {
  const [playing, setPlaying] = React.useState(false);
  const { load, stop } = useAudioPlayer()
  const playRadio = () => {
    load("https://icecast.radiofrance.fr/fip-midfi.mp3?id=radiofrance", {
      autoplay: true,
      html5: true,
      format: "mp3"
    });
  }
  const toggleRadio = () => {
    if (playing) {
      stop()
    } else {
      playRadio()
    }
    setPlaying(!playing)
  }
  return <div style={styles.radio}>
    <button style={styles.button} onClick={toggleRadio}>{playing ? 'Stop' : 'Play' }</button>;
  </div>
};


const styles = {
  radio: {
    backgroundImage: `url(${radio})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "absolute",
    width: "400px",
    height: "400px",
    bottom: "1%",
    right: "0%",
    padding: 10
  },
  button: {
    position: "absolute",
    top: "18%",
    right: "31%",
    opacity: 0,
    backgroundColor: "transparent",
    border: "none",
    color: "white",
    fontSize: "1.5em",
    cursor: "pointer",
    zIndex: 1000000
  }
}

export default Radio;
