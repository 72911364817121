import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import headerFile from "../pages/header.txt"
import bodyFile from "./body.md"
import Markdown from "react-markdown";
import 'normalize.css';

const SimplePage = () => {
    const [header, setHeader] = useState('');
    const [body, setBody] = useState('');
    useEffect(() => {
        fetch(headerFile).then(response => response.text()).then(setHeader);
        fetch(bodyFile).then(response => response.text()).then(setBody);
    }, []);
    return (<div>
        {window.innerWidth > 900 && <><br/><br/><Link style={styles.link} to={'/'}>Back to Artembo's workplace</Link></>
}
    <pre style={styles.header}>
            {header}
        </pre>
        <pre style={{...styles.text, ...styles.container}}>
            <Markdown
                children={body}
            />
        </pre>
    </div>);
}


const styles = {
    link: {
        padding: "0 0 20px 20px",
        color: "black"
    },
    container: {
        padding: "0 20px 20px 20px",
        position: "relative"
    },
    header: {
        padding: "0 20px 0 20px",
        whiteSpace: "pre",
        overflow: "auto",
        scrollbarWidth: "none"
    },
    text: {
        whiteSpace: "pre-line",
        wordBreak: "break-word"
    }
}


export default SimplePage
