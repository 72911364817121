import {useXTerm} from "react-xtermjs";
import React, {useEffect} from "react";
import laptop from "../assets/images/laptop.png";
import { WebLinksAddon } from 'xterm-addon-web-links';


const red = text => `\x1b[1;31m${text}\x1b[37m`;
const blue = text => `\x1b[1;34m${text}\x1b[37m`;

const commands = {
    help: `Available commands: ${red("help")}, ${red("about")}, ${red("contact")}, ${red("company")}`,
    about: "I'm a software engineer based in Novi Sad, Serbia",
    contact: `${blue("Email:")} artem@artembo.com\r\n` +
        `${blue("GitHub:")} https://github.com/artembo\r\n` +
        `${blue("LinkedIn:")} https://www.linkedin.com/in/artembo/\r\n` +
        `${blue("Telegram:")} https://t.me/artembo`,
    whoami: "You are a wonderful person",
    company: `${blue("Name:")} Artem Morozov pr Računarsko programiranje Novi Sad\r\n` +
        `${blue("PIB:")} 113974458\r\n` +
        `${blue("MB:")} 67228758\r\n` +
        `${blue("Address:")} Gajeva 1-3, 1 sprat, 21000, Novi Sad, Serbia`
}

const completeCommand = (command) => {
    const keys = Object.keys(commands);
    const matches = keys.filter(key => key.startsWith(command));
    if (matches.length === 1) {
        return matches[0];
    }
    return "";
}

const welcomeMessage = `
┌─┐┬─┐┌┬┐┌─┐┌┬┐┌┐ ┌─┐ ┌─┐┌─┐┌┬┐\n\r├─┤├┬┘ │ ├┤ │││├┴┐│ │ │  │ ││││\n\r┴ ┴┴└─ ┴ └─┘┴ ┴└─┘└─┘o└─┘└─┘┴ ┴\n\r${commands.help}
`;


const execute = (command) => {
    return commands[command] || `bash: ${command}: command not found`
}

const Laptop = () => {
    const {instance, ref} = useXTerm();
    const [buffer, setBuffer] = React.useState({value: "", command: ""});

    useEffect(() => {
        if (buffer.command === "newline") {
            instance.write("\r\n");
            if (buffer.value.length > 0) {
                instance.writeln(execute(buffer.value))
            }
            setBuffer(buffer => ({...buffer, value: "", command: ""}))
            instance.write("artem@artembo.com:~# ");
        } else if (buffer.command === "tab") {
            const completed = completeCommand(buffer.value);
            if (completed.length > 0) {
                console.log('inside')
                for (let i = 0; i < buffer.value.length; i++) {
                    instance.write("\b \b")
                }
                instance.write(completed)
                setBuffer(buffer => ({...buffer, value: completed, command: "type"}))
            }
        } else if (buffer.command === "backspace") {
            instance.write("\b \b")
        }
    }, [buffer, instance]);
    useEffect(() => {
        if (instance) {
            instance.loadAddon(new WebLinksAddon());
            // write welcome message with a delay and then show the prompt
            for (let i = 0; i < welcomeMessage.length; i++) {
                setTimeout(() => {
                    instance.write(welcomeMessage[i])
                    if (i === welcomeMessage.length - 1) {
                        instance.write("\r\n");
                        instance.write("artem@artembo.com:~# ");
                    }
                }, i * 20)
            }

            instance.onData(key => {
                const charCode = key.charCodeAt(0)
                if (charCode === 127) {
                    setBuffer(buf => ({...buf, value: buf.value.length > 0 ? buf.value.slice(0, -1) : buf.value, command: buf.value.length > 0 ? "backspace" : "null"}))
                } else if (charCode === 9) {
                    setBuffer(buf => ({...buf, value: buf.value, command: "tab"}))
                } else if (charCode === 13) {
                    setBuffer(buf => ({...buf, value: buf.value, command: "newline"}))
                } else if (key === '\x1B[A' || key === '\x1B[B' || key === '\x1B[C' || key === '\x1B[D') {
                    // Ignore arrow keys
                } else {
                    instance.write(key)
                    setBuffer(buf => ({...buf, value: buf.value + key, command: "type"}))
                }
            });
        }
    }, [instance]); // The effect will run only once when the `instance` is initialized.

    return <div style={styles.container}>
        <div style={styles.laptop}>
            <div ref={ref} style={styles.terminal}/>
        </div>
    </div>
}

const styles = {
    container: {
        position: "absolute",
        bottom: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        width: "100%",
        maxHeight: "100%",
        maxWidth: "960px",
        aspectRatio: "16/9",
    },
    laptop: {
        flex: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "top",
        borderRadius: "10px",
        backgroundImage: `url(${laptop})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        paddingLeft: "6%",
        paddingRight: "6%",
        paddingTop: "1.5%",
        paddingBottom: "1.5%",
        aspectRatio: "16/9",
    },
    terminal: {
        width: "68.2%",
        marginTop: "2.6%",
        boxSizing: "border-box",
    }
};

export default Laptop
