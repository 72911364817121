import Laptop from "./components/laptop";
import Radio from "./components/radio";
import Table from "./components/table"
import React from "react";
import {Link} from "react-router-dom";
import './App.css';

const App = () => {
    return <Table>
        <Link style={styles.link} to={'index.html'}>Simplified version</Link>
        <Radio />
        <Laptop />
    </Table>
}


const styles = {
    link: {
        color: "black",
        background: "white",
        padding: "10px 20px",
        borderRadius: "10px",
        margin: "10px",
        zIndex: "10000",
        position: "absolute",
        opacity: "0.5",
    }
}

export default App;
